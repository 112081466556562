import React from 'react';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import Home from './Home';
import Olympiod from './Olympiod';
import Getfranchise from './Getfranchise';
import Worksheet from './Worksheet';
import Worksheetload from './Worksheetload';
import Worksheetlkg from './Worksheetlkg';
import Worksheetukg from './Worksheetukg';
import Cbsesyllabus from './Cbsesyllabus';
import Navbar from './Navbar';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Footer from './Footer';
import Error from './Error';


const App = () => {

  return (
    <>
    <Router basename='/'>
    <Navbar />

    

    <Routes>
            <Route exact path="/" element={ <Home />} />
            <Route path="/Home" element={ <Home />} />
            <Route path="/Worksheets" element={ <Worksheet />} />
           <Route path="/Worksheetload" element={<Worksheetload />} />
            <Route path="/Worksheetlkg" element={<Worksheetlkg />} />
            <Route path="/Worksheetukg" element={<Worksheetukg />} />
          <Route path="/Olympiod" element={<Olympiod />} />
          <Route path="/cbsesyllabus" element={<Cbsesyllabus />} />
          <Route path="/GetFranchise" element={<Getfranchise />} />
          
        <Route path="/*" element={ <Error />} />
       
    </Routes>
</Router>
<Footer />


  </>
  
  );
}

export default App;
