import React, { useRef,useEffect } from 'react';
import Banner from './Banner';
import Block1 from './Block1';
import Ourbooks from './Ourbooks';
import { Spring, animated } from 'react-spring';
import Ethics from './Ethics';
import Col3 from './Col3';
import './home.css';
import {TweenMax,Power3} from 'gsap';



const Home = () => {
  let bannerText = useRef(null);

  useEffect(()=>{
TweenMax.to(
  bannerText,
  .8,{
    opacity:1,
    y:-50,
    ease:Power3.easeInOut,
    delay:.2
  }
)
  },[])



  return (
    
    
    
    <>
    <div   ref={el => {bannerText=el}}>
     <Banner />
     </div>
   
    

<div className="container-fluid nav_bg">
        <div className='row'>
            <div className="col-10 mx-auto">
              <div className='row gy-4'>


              <div className='col-md-12 jy'>
              <h1 className='padding-title'>Let’s Make Learning Fun For Everyone!</h1>
              <p>Kidzibooks Publications is a leading Early learning Books publisher in India. We publish Preschool Books, Activity Books, Colouring Books, Picture Dictionaries, Story Books, Quiz Books, and much more! Explore our exclusive range of books and dwell in the world of learning!
Books syllabus for each class is aligned to the principles outlined in the National Education Policy 2020 – A Blended Learning Solution for the evolving needs of Teachers and Students.

Our Books have been designed for cognitive development in children from an early age, keeping in mind the international standards. Our Books make learning a pleasant and enjoyable experience for little explorers.</p>   <p>Kidzibooks Publications is a leading Early learning Books publisher in India. We publish Preschool Books, Activity Books, Colouring Books, Picture Dictionaries, Story Books, Quiz Books, and much more! Explore our exclusive range of books and dwell in the world of learning!
Books syllabus for each class is aligned to the principles outlined in the National Education Policy 2020 – A Blended Learning Solution for the evolving needs of Teachers and Students.

Our Books have been designed for cognitive development in children from an early age, keeping in mind the international standards. Our Books make learning a pleasant and enjoyable experience for little explorers.</p>
              </div>
       
        
        
</div>
</div>
</div>
</div>

 <div className="container-fluid nav_bg">
        <div className='row'>
            <div className="col-10 mx-auto">
              <div className='row gy-4'>

              <div className="col-md-10 p-5 h-100% pt-lg-0 order-1 order-lg-1 d-flex justify-content-center flex-column">



  </div>

  



            
      
           
    
</div>
</div>
</div>
</div>
     





<div ref={el => {bannerText=el}} >
<Ourbooks  />
</div>


<Block1 />

  
<Ethics />
  
<Col3 />

 
</>

   
  );
}

export default Home;
