import React from 'react';
const Cbsesyllabus = () => {
    return (
      <>

<h1>Cbsesyllabus page</h1>
      </>

);
}

export default Cbsesyllabus;
