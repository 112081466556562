 
 import React from 'react';
 import blockright from "../src/images/kidspainting.png";
 import {motion} from 'framer-motion';
  const Col3 = () => {
     return (
       <>
       <section id="header8">
  <div className="container-fluid nav_bg">
         <div className='row'>
             <div className="col-12 mx-auto" >
 
               <div className="row align-items-center p-5">
              <br></br>
               <div className="col-md-4  pt-lg-0 order-1 order-lg-2 d-flex justify-content-center flex-column">
              <motion.img src={blockright} alt="kidzibooks"
              drag
              dragConstraints={{
                top: -50,
                left: -50,
                right: 50,
                bottom: 50,
              }}
              ></motion.img>
                       </div>
 
         <div className='col-lg-4   order-2 order-lg-2  justify-content-center header-img1'>
         <h3>Nurture your children’s future.</h3>
         <p>Reading Storybooks takes children into a wonderland where they can be anything they want. Nurture the love of reading in your child and they will have a friend for life!</p>
       <h4>Let’s Make Learning Fun For Everyone!</h4>
       <p>Franchise Opportunity – Become a Publisher with Us!</p>
         
         </div>
 
         <div className="col-md-4  pt-lg-0 order-3 order-lg-3 d-flex justify-content-center flex-column">
         <h3>	
 Contact Us</h3>
         <p>9971052240</p>
 
         </div>
 
     </div>
 
     
 
 
 
   
   </div>
    </div>
 </div>


 </section>
         </>
 
    
         );
       }
       
       export default Col3;
       
 
  