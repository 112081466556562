import React from 'react';
import  { Link } from "react-router-dom";
import  web from "../src/images/worksheet1.png";
import work1 from "../src/images/worksheet2.png";
import work2 from "../src/images/worksheet3.jpeg";
import work3 from "../src/images/worksheet4.png";
import work4 from "../src/images/worksheet5.png";
import work5 from "../src/images/worksheet6.png";


const Worksheetload = () => {
  return (
    <>
   <div className='my-5'>
    
    <Link className="nav-link" aria-current="page" to="/">Back</Link>
    <h1 className='text-center'>Nursery Worksheets</h1>
   </div>
   <div className="container-fluid nav_bg">
        <div className='row'>
            <div className="col-10 mx-auto">
              <div className='row gy-4'>

              <div className='row gy-4'>

<div className="col-md-4 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column">           
<img src={web}  alt="circle" className=''></img>
<a href="https://drive.google.com/file/d/1J_R5QGuGUFDWEqQMdh-7Ug9Y2k9yY6FN/view?usp=share_link">Download</a>
</div>

 <div className="col-md-4 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column">
<img src={work1}  alt="rectangle" className=''></img>
<a href="https://drive.google.com/file/d/1H_S1zkDmISeTKEJJ_8vxavQHrTY3l7uq/view?usp=share_link">Download</a>
</div>


<div className="col-md-4 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column">
<img src={work2}  alt="oval" className=''></img>
<a href="https://drive.google.com/file/d/1xqQOyYVDszmJ34hAnLxW6j5iGfj0abK9/view?usp=share_link">Download</a>
</div>

</div>

<div className='row gy-4'>

<div className="col-md-4 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column">           
<img src={work3}  alt="heart" className=''></img>
<a href="https://drive.google.com/file/d/1Otav9SR36VAgEQob4AHQr04nMJE4MkvM/view?usp=share_link">Download</a>
</div>

 <div className="col-md-4 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column">
<img src={work4}  alt="triangle" className=''></img>
<a href="https://drive.google.com/file/d/1qkgxcOnok2p0D_mX1ARKAazascypqk6m/view?usp=share_link">Download</a>
</div>


<div className="col-md-4 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column">
<img src={work5}  alt="rhombus" className=''></img>
<a href="https://drive.google.com/file/d/1sgORN5NYHyVL9xkRzPRkCGoN8pGTRt87/view?usp=share_link">Download</a>
</div>

</div>



          

              </div>



              </div>
              </div>
              </div>

</>

   
  );
}

export default Worksheetload;
