import React from 'react';
import './Block1.css';
import blockright from "../src/images/p9.jpg";
import {motion} from 'framer-motion';
import { Spring } from 'react-spring';
const Block1 = () => {
  return (
    <>
    <section id="header1" className="p-5 d-flex align-items-center">

    <div className="container-fluid nav_bg pdn">
        <div className='row'>
            <div className="col-12 mx-auto">

              <div className="row jy">
             
              <motion.div className="col-md-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column"
       initial={{ opacity: 0.6 }}
       whileHover={{
         scale: 1.2,
         transition: { duration: 1 },
       }}
       whileTap={{ scale: 0.9 }}
       whileInView={{ opacity: 1 }}
              >
             
        <h4><b>DISCOVER FUN LEARNING BOOKS WITH INDIA’S TOP LEADING PUBLISHER</b></h4>
        
        <p>Kidzibooks Publications is a leading Early learning Books Publisher in India.

We publish Early Learning Book Sets, Activity Books, Colouring Books, Picture Dictionaries, Story Books, Quiz Books and much more! Explore our exclusive range of books and dwell into the world of learning!

The Kidzibooks Books Sets for each class are aligned to the principles outlined in the National Education Policy 2020 – A Blended Learning Solution for the evolving needs for Teachers and Students.

Our Books have been designed for cognitive development in children from an early age, keeping in mind the international standards. Our Books make learning a pleasant and enjoyable experience for the little explorers.</p>
        </motion.div>

        <motion.div className='col-lg-6 order-1 order-lg-2 header-img1'
          whileHover={{scale:1.2,rotat:90 }}
          whileTap={{
            scale:0.8,
            rotate:-90,
            borderRadius:"100%"

          }}
        
        >

<img src={blockright} className="img-fluid animated" alt="home img"/>
</motion.div>


    </div>
  
  </div>
   </div>
</div>
</section>
</>

   
  );
}

export default Block1;





