import React from 'react';
import {Link} from "react-router-dom";
import  web from "../src/images/worksheet1.png";
import work1 from "../src/images/worksheet2.png";
import work2 from "../src/images/worksheet3.jpeg";
import work3 from "../src/images/worksheet4.png";
import work4 from "../src/images/worksheet5.png";
import work5 from "../src/images/worksheet6.png";


const Worksheetload = () => {
  return (
    <>
   <div className='my-5'>
   <Link className="nav-link" aria-current="page" to="/">Back</Link>
    <h1 className='text-center'>UKG Worksheets</h1>
   </div>
   <div className="container-fluid nav_bg">
        <div className='row'>
            <div className="col-10 mx-auto">
              <div className='row gy-4'>

              <div className='row gy-4'>

<div className="col-md-4 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column">           
<img src={web}  alt="kidz" className=''></img>
<a href="https://drive.google.com/file/d/1olrsMytrSaSOYaQG3_qI3HWX4evogx9R/view?usp=sharing">Download</a>
</div>

 <div className="col-md-4 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column">
<img src={work1}  alt="kidz" className=''></img>
<a href="https://drive.google.com/file/d/1CQyA9UWZwId2GfwQ1lKjBhWOuki2tpyx/view?usp=sharing">Download</a>
</div>


<div className="col-md-4 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column">
<img src={work2}  alt="kidz" className=''></img>
<a href="https://drive.google.com/file/d/1AICXnH13WAueN1RxvKnPjnh_wSOvXEKz/view?usp=sharing">Download</a>
</div>

</div>

<div className='row gy-4'>

<div className="col-md-4 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column">           
<img src={work3}  alt="kidz" className=''></img>
<a href="https://drive.google.com/file/d/14a1JeMMn-jxDlvK7_rLc3Fd7lF7I5K-w/view?usp=sharing">Download</a>
</div>

 <div className="col-md-4 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column">
<img src={work4}  alt="kidz" className=''></img>
<a href="https://drive.google.com/file/d/19aSdrIaST6w_z47Roh6Iq8qjdTDnz91d/view?usp=sharing">Download</a>
</div>


<div className="col-md-4 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column">
<img src={work5}  alt="kidz" className=''></img>
<a href="https://drive.google.com/file/d/1QcOJh-ZB9TswGZu3vLHKlU2w9HnCyRHh/view?usp=sharing">Download</a>
</div>

</div>



          

              </div>



              </div>
              </div>
              </div>

</>

   
  );
}

export default Worksheetload;
