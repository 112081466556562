import React from 'react';
import './Footer.css';
import logo from "../src/images/kidzilogo.png";
import blockright from "../src/images/foot.png";
import line from "../src/images/line.png";


const Footer = () => {
  return (
    <>
    <footer className="w-100 my-5 p-5 d-flex align-items-center">
    <div className="container-fluid nav_bg">
        <div className='row'>
        <img src={line} className="img-fluid " alt="home img"/>
            <div className="col-12 mx-auto">

              <div className="row">
             
              <div className="col-md-4  pt-lg-0 order-1 order-lg-2 d-flex justify-content-center flex-column">
              <br></br>
              <img src={logo} className="img-fluid animated" alt="home img"/>
        
        <p>Kidzibooks Publications is a leading Early learning Books publisher in India. We publish Preschool Books, Activity Books, Colouring Books, Picture Dictionaries, Story Books, Quiz Books and much more! Explore our exclusive range of books and dwell into the world of learning</p>
 </div>

        <div className='col-lg-4 order-2 order-lg-2 justify-content-center header-img1'>
        <br></br>
<img src={blockright} className="img-fluid animated" alt="home img"/>
</div>

<div className="col-md-4 pt-5 pt-lg-0 order-3 order-lg-3 d-flex justify-content-center flex-column">
<h1>Contact Us</h1>
<p>Phone: +91 9971052240</p>
<p>Email: kidzibooks@gmail.com</p>
<p>Address: 8th Floor, HB Twin Towers, Netaji Subhash Place, Delhi</p>
</div>




    </div>
  
  </div>
   </div>
</div>
</footer>
</>

   
  );
}

export default Footer;





