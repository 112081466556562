import React from 'react';
import  './Getfranchise.css';
import  franc from "../src/images/Franchising.jpg";

const Getfranchise = () => {
  return (
    <>
  
    <div className='my-5 bck'>
    <center><h1><span style={{background:"white",padding:"5px",width:"420px",radius:"10px"}}>Get Franchise</span></h1> </center>

    
   </div>
   
   <h2 className='text-center'>Become a Book Publisher for Schools in India.  </h2>
   
   <div className="container-fluid nav_bg">
        <div className='row'>
            <div className="col-10 mx-auto">
              <div className='row gy-4'>
<img src={franc}  alt="kidzibooks"></img>
</div>
</div>
</div>
</div>

<div className="container-fluid nav_bg">
        <div className='row'>
            <div className="col-10 mx-auto">
              <div className='row gy-4'>

                <h1>Major Attractions</h1>
<p>
Research-Based World-class Curriculum as per International Standard

</p>

<p>
The books have been designed keeping in mind the latest curriculum and cognitive thinking. They are already used in many reputed schools in India.

</p>
<p>Investment After Sales – All you need to get started is ONE employee and that is all! We provide you with the basic training and also the marketing material needed to approach schools.</p>

<p>
No fixed expenses – No fixed expenses like electricity and rent per month. All you need is a marketing field employee who can visit schools and target sales.
</p>
<p>
Leads and Sales Support from Head Office – We will provide you with support on prospective leads that you can follow to crack sales.- Visible return on Investment
</p>
<p>
Excellent return on your investment and strong business opportunity with Long Term success
</p>
<p>
Training for Employee – World class training will be provided to the employee for targeting Sales.

</p>
<p>
Material for Advertisements – We will provide you with digital material that can be used for marketing and promotions.

</p>


</div>
</div>
</div>
</div>


<div className="container-fluid nav_bg">
        <div className='row'>
            <div className="col-10 mx-auto">
              <div className='row gy-4'>
                <h1>FAQ-</h1>
<p>
Franchise Signup – Please deposit franchise fee along with GST and required documents to proceed with agreement and block your expected location.
</p>
<p>  Site Searching – After signing the MoU with 100% franchise fee, our team will assist you in school searching.</p>
<p> Starting Initial Promotion – Local area promotion can start immediately. Company will start online promotions simultaneously.</p>
<p>  Recruitment of Staff- You need just one employee of sales and marketing background to start with.</p>
<p> Training of Staff – Online training will be organized by our team.</p>
<p>  Day to day operation – Just after hiring sales Manager, day to day operations will start and will be guided and monitored by the Head Office.</p>

<h2>
Enclosing Social media connect for your reference:
</h2>
<p>Website – www.kidzibooks.com</p>
<p>E-Mail – kidzibooks@gmail.coms</p>
<p>Facebook – https://www.facebook.com/Kidzi-Books-103927782215301</p>
<p>Instagram – https://www.instagram.com/kidzibooks/?utm_medium=copy_link</p>
<p>Let’s together commit to transform early childhood education in India, the way we teach our future leaders. Our motto is <b>“Learning Made Fun”</b>.</p>
</div>
</div>
</div>
</div>




</>

   
  );
}

export default Getfranchise;
