import React from 'react';
const Olympiod = () => {
    return (
      <>

<h1>Olympiod page</h1>



      </>

);
}

export default Olympiod;
