import React from 'react';

import  web from "../src/images/worksheet1.png";
import work1 from "../src/images/worksheet2.png";
import work2 from "../src/images/worksheet3.jpeg";
import work3 from "../src/images/worksheet4.png";
import work4 from "../src/images/worksheet5.png";
import work5 from "../src/images/worksheet6.png";
import { useNavigate } from 'react-router-dom';

const Worksheet = () => {

  const navigate = useNavigate();
  return (
    <>
   <div className='my-5'>
 
    <h1 className='text-center'>Our Worksheets</h1>
   </div>
   <div className="container-fluid nav_bg">
        <div className='row'>
            <div className="col-10 mx-auto">
              <div className='row gy-4'>

              <div className='row gy-4'>

<div className="col-md-4 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column">           
<button onClick={() => {
navigate("/Worksheetload")

}}

style={{
  backgroundImage: "linear-gradient(red,red)",
  color: "white",
  padding:40,
  border:0,
  fontSize:22,
}}>
  {""}
  Nursery{""}
  </button>

</div>
<div className="col-md-4 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column">           
<button onClick={() => {
navigate("/Worksheetlkg")

}}
style={{
  backgroundImage: "linear-gradient(Orange,Orange)",
  color: "white",
  padding:40,
  border:0,
  fontSize:22,
}}>
  {""}
  LKG{""}
  </button>

</div>


<div className="col-md-4 pt-5 pt-lg-0 order-2 order-lg-1 d-flex justify-content-center flex-column">           
<button onClick={() => {
navigate("/Worksheetukg")

}}
style={{
  backgroundImage: "linear-gradient(blue,blue)",
  color: "white",
  padding:40,
  border:0,
  fontSize:22,
}}>
  {""}
  UKG{""}
  </button>

</div>

 
</div>





          

              </div>



              </div>
              </div>
              </div>

</>

   
  );
}

export default Worksheet;
